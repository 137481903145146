import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../assets/scss/Code.scss';

class Code extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lotteryCode: '',
      inputError: false,
      invalidCodeMessage: '',
    };
  }

  handleLotteryCodeChange = (e) => {
    this.setState({ lotteryCode: e.target.value, inputError: false, invalidCodeMessage: '' });
  };

  handleSubmit = () => {
    if (this.state.lotteryCode.trim() === '') {
      this.setState({ inputError: true });
    } else {
      this.props.onLotteryCodeSubmitted(this.state.lotteryCode);
    }
  };

  render() {
    const { showCodeDialog, invalidCodeMessage } = this.props;
    const { inputError } = this.state;
    // console.log('invalidCodeMessage:', invalidCodeMessage);
    return (
        <div className="code-wrap">
          {showCodeDialog && (
              <div>
                <div className="mask"></div>
                <CSSTransition
                    in={showCodeDialog}
                    timeout={800}
                    classNames="bounce-in"
                    unmountOnExit
                >
                  <div className="con">
                    <h3 className="title">Please enter the lottery code.</h3>
                    <input
                        type="text"
                        value={this.state.lotteryCode}
                        onChange={this.handleLotteryCodeChange}
                        className={inputError? 'error-input' : ''}
                    />
                    {inputError && (
                        <span className="error-text">The lottery code cannot be empty</span>
                    )}
                    {invalidCodeMessage && (
                        <span className="invalid-code-text">{invalidCodeMessage}</span>
                    )}
                    <button className="btn" onClick={() => this.handleSubmit()}>
                      Submit
                    </button>
                  </div>
                </CSSTransition>
              </div>
          )}
        </div>
    );
  }
}

export default Code;