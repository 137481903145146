import React, { Component, Fragment } from 'react';
import './assets/scss/App.scss';
import Header from './components/Header.js';
import Turntable from './components/Turntable.js';
import Rule from './components/Rule.js';
import Code from './components/Code.js';
import chrismas from './assets/music/chrismas.mp3';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      err: '',
      logo: '',
      turntable: [],
      rule: '',
      showCodeDialog: true,
      lotteryCode: '',
    };
    this.audioRef = React.createRef();
  }

  render() {
    const { loading, err, logo, turntable, rule, showCodeDialog, lotteryCode } = this.state;

    let con;
    if (loading) {
      con = <div className="loading">Loading</div>;
    }
    if (err) {
      con = <div className="err">{err}</div>;
    }
    if (!loading &&!err) {
      con = (
          <Fragment>
            <Header logo={logo} />
            <Turntable turntable={turntable} lotteryCode={lotteryCode} />
            <Rule rule={rule} />
          </Fragment>
      );
    }
    // console.log('Turntable props:', { turntable, lotteryCode: this.state.lotteryCode });
    return (
        <div className="app">
          {con}
          {showCodeDialog && (
              <Code
                  showCodeDialog={showCodeDialog}
                  onLotteryCodeSubmitted={this.handleLotteryCodeSubmitted}
                  onClose={this.handleClose}
                  invalidCodeMessage={this.state.invalidCodeMessage}
              />
          )}
        </div>
    );
  }

  componentDidMount() {
    this.getData();
    const audio = new Audio(chrismas);
    this.audioRef.current = audio;
    document.addEventListener('touchstart', () => {
      audio.play().then(() => {
        // console.log('音乐开始播放');
      }).catch(error => {
        // console.error('音乐播放失败', error);
      });
    });
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      // const response = await fetch('http://localhost:8080/lottery');
      // const response = await fetch('http://lottery.yi-ben.com:8080/lottery');
      const response = await fetch('https://api.yi-ben.com:8080/lottery');
      if (response.ok) {
        const data = await response.json();
        this.setState({
          loading: false,
          logo: '',
          rule: '<ol><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Please enter your lottery code and submit it<br></span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Click the GO button to start the lottery and screenshot the results for the staff<br></span></li><li style="text-align: left;"><span style="color: rgb(255, 255, 255);">Please contact the staff for specific rules</span><br></li></ol>',
          turntable: data,
        });
      } else {
        // console.error('API 请求失败');
        this.setState({ loading: false });
      }
    } catch (error) {
      // console.error('发生错误：', error);
      this.setState({ loading: false });
    }
  };

  handleLotteryCodeSubmitted = async (code) => {
    try {
      // const response = await fetch('http://localhost:8080/submitCode', {
      const response = await fetch('https://api.yi-ben.com:8080/submitCode', {
      // const response = await fetch('http://lottery.yi-ben.com:8080/submitCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data && data.length > 0) {
          this.setState({
            showCodeDialog: false,
            lotteryCode: code,
            turntable: data,
            someOtherState:!this.state.someOtherState,
          });
          // 存储抽奖码到 localStorage
          localStorage.setItem('lotteryCode', code);
        } else {
          // console.log('抽奖码无效，数据为空数组');
          this.setState({
            showCodeDialog: true,
            invalidCodeMessage: 'Invalid lottery code. Please try again.',
          });
        }
      } else {
        // console.error('抽奖码提交失败');
      }
    } catch (error) {
      // console.error('发生错误：', error);
    }
  };

  handleClose = () => {
    this.setState({ showCodeDialog: false });
  };
}

export default App;