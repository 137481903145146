import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import '../assets/scss/Dialog.scss';

export default class Dialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      // destoryTime: 5 // 不再需要倒计时时间
    };
  }

  componentWillReceiveProps(nextProps) {
    const { show } = nextProps.dialog;
    this.setState({
      show: show,
      // destoryTime: nextProps.dialog.destoryTime // 不再需要倒计时时间
    });
  }

  render() {
    const { title, resultImg, resultText, lotteryCode } = this.props.dialog;
    const { show } = this.state;

    return (
        <div className="dialog-wrap">
          <div className={show? 'mask show' : 'mask hide'}></div>
          <CSSTransition
              in={show}
              timeout={800}
              classNames="bounce-in"
              unmountOnExit
          >
            <div className="con">
              <h3 className="title">{title}</h3>
              <div className="main">
                <img className="img" src={resultImg} alt="" />
                <span className="text">{lotteryCode}</span>
                <span className="text">{resultText}</span>
              </div>
              {/* <button className="btn">{destoryTime}s 返回页面</button> */}
              {/*<button className="btn">Back</button>*/}
            </div>
          </CSSTransition>
        </div>
    );
  }
}